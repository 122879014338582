// base colors
$color-white: #ffffff;
$color-black: #000000;

$color-light: #F0F0F0;
$color-dark: #2B2C33;
$color-gray: #A6A7B3;
$color-orange: #F15B40;
$color-red: #d9230a;
$color-pink: #FFDECD;

$color-accent: $color-gray;
$color-primary: $color-dark;
$color-secondary: $color-light;

// colors by function
$color-title: $color-dark;
$color-text: $color-dark;
$color-background: $color-white;
