.info {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    padding: grid-size(3) grid-size(1);

    @include mq(lg) {
      padding: grid-size(3) grid-size(1);

      grid-template-columns: calc(50% - #{grid-size(2)}) 50%;
      column-gap: grid-size(2);
    }
  }

  .u-title {
    margin-bottom: grid-size(0.25);
  }

  &__filters {
    margin: grid-size(0.75) 0 grid-size(1);

    .filter-button {
      margin-bottom: 20px;
    }
  }

  //&__column,
  &__accordions-container {
    position: relative;
  }

  //.info__column--picture {
  //  @include mq($until: lg) {
  //    display: none;
  //  }
  //}

  //.info__item {
  //  @include full();
  //
  //  display: flex;
  //  align-items: flex-start;
  //  justify-content: center;
  //
  //  padding-top: grid-size(4);
  //
  //  &--restaurant .info__picture-cont {
  //    margin-top: -15%;
  //    width: 100%;
  //  }
  //
  //  &--customer .info__picture-cont {
  //    margin-top: -35%;
  //    width: 80%;
  //
  //    [data-picture] {
  //      transform: translate(-50%, -50%) scale(1.02);
  //    }
  //  }
  //
  //  .info__picture-cont {
  //    position: relative;
  //  }
  //
  //  [data-picture] {
  //    position: absolute;
  //    top: 50%;
  //    left: 50%;
  //    width: 100%;
  //
  //    transform: translate(-50%, -50%);
  //  }
  //
  //  .info__picture-cont [data-frame] {
  //    position: relative;
  //    width: 100%;
  //  }
  //}

  &__accordions-wrapper {
    position: relative;
    width: 100%;

    &:not(.info__accordions-wrapper--active) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}

.accordion {
  position: relative;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid $color-gray;
  }

  &__head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: grid-size(1) 0;
    font-size: 20px;
    font-weight: 900;

    cursor: pointer;

    h4 {
      pointer-events: none;
    }

    img {
      width: 12px;
      pointer-events: none;
    }

    @include mq(lg) {
      padding: 20px 0;
    }
  }

  &__title {
    font-weight: normal;

    transition: color 0.75s $ease-base;
  }

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;

    background-color: $color-gray;

    hr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: $color-dark;
      transform-origin: left;
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;

    transition: max-height 1.25s $ease-base;

    img {
      position: relative;
      width: 100%;

      @include mq(lg) {
        display: none;
      }
    }
  }

  &__wrapper {
    padding: grid-size(0.5) 0 grid-size(1.5);

    @include mq($and: $is-not-touch-device) {
      transform: translateY(grid-size(-1));
      opacity: 0;

      transition: opacity 0.5s $ease-base,
      transform 0.5s $ease-base;
    }

    @include mq(lg) {
      padding: 0 0 20px;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &.accordion--active {
    .accordion__head img {
      transform: rotate(180deg);
    }

    .accordion__title {
      color: $color-orange;
    }

    .accordion__button {
      transform: rotate(0);
    }

    .accordion__line {
      height: 4px;
    }

    @include mq($and: $is-touch-device) {
      .accordion__line hr {
        transform: scaleX(1) !important;
      }
    }
    @include mq($and: $is-not-touch-device) {
      .accordion__wrapper {
        opacity: 1;
        transform: translateY(0);

        transition: opacity 1.25s $ease-base,
        transform 1.25s $ease-base;
      }
    }
  }
}
