.sign-in {
  position: relative;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(ipadP) {
    padding: 100px 20px;
  }

  .form {
    max-width: 300px;

    &__logo {
      width: 50%;
      margin-left: 25%;
      margin-bottom: 50px;
    }
  }

  .checkbox-container {
    margin-top: 0;
  }

  .form__sign-in {
    width: 100%;
    margin-top: 20px;

    text-align: center;
    font-size: 13px;

    a {
      color: $color-orange;
      text-decoration: underline;
    }
  }
}

.form_link {
  color: $color-orange;
  text-decoration: underline;
}