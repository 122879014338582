$font-family-base: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-h: 'Nunito Sans';

// font-base sans
$fluid-section-title: (
        m-font-size: 25px,
        d-font-size: 42px,
        m-line-height: 30px,
        d-line-height: 44px,
);
$fluid-section-eyelet: (
        m-font-size: 14px,
        d-font-size: 14px,
        m-line-height: 19px,
        d-line-height: 19px,
);
$fluid-base: (
        m-font-size: 16px,
        d-font-size: 16px,
        m-line-height: 22px,
        d-line-height: 22px,
);
$fluid-s: (
        m-font-size: 16px,
        d-font-size: 18px,
        m-line-height: 26px,
        d-line-height: 30px,
);
$fluid-m: (
        m-font-size: 20px,
        d-font-size: 20px,
        m-line-height: 24px,
        d-line-height: 24px,
);
$fluid-nav: (
        m-font-size: 22px,
        d-font-size: 11px,
        m-line-height: 22px,
        d-line-height: 11px,
);
