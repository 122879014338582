.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: grid-size(0.25) grid-size(1.5);

  background-color: $color-background;
  border-bottom: 1px solid lighten($color-dark, 60%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: z-index(header);

  &__button:not(.u-button-orange) {
    padding: 0;

    img {
      height: grid-size(1);
      min-height: 35px;
      max-height: 45px;
    }
  }

  &__voice {
    display: inline-block;
    margin-left: grid-size(0.75);

    font-weight: 800;
    font-size: 11px;
    letter-spacing: 1px;

    a {
      font-size: inherit;
      font-weight: inherit;
    }
  }

}