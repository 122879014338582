$dot-size: 1px;
$dot-space: 10px;

.process {
  background-color: $color-dark;

  &__wrapper {
    padding: grid-size(3) grid-size(1);

    @include mq(ipadP) {
      padding: grid-size(3) grid-size(1) grid-size(4);
    }
  }

  h3,
  h4,
  p {
    color: $color-white;
  }

  h4 {
    font-size: 14px;
    letter-spacing: 1px;
  }

  .u-title {
    margin: grid-size(0.5) 0 grid-size(1);
  }

  &__icon-list {
    position: relative;
  }

  .icon-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($until: ipadP) {
      flex-flow: column nowrap;
    }

    &[data-list="customer"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__item {
      position: relative;
      white-space: nowrap;

      &--dotted {
        flex: 1 0 auto;
        height: 1px;
        margin: 0 grid-size(0.5);

        svg {
          vertical-align: top;

          &.line--mbl {
            display: none;
          }
        }

        line {
          stroke-dasharray: 4;
          stroke-dashoffset: 8;
          animation: line .6s linear infinite;
        }
      }

      @include mq($until: ipadP) {
        width: 100%;
        text-align: center;

        svg.line--dsk {
          display: none;
        }
        svg.line--mbl {
          display: block;
        }

        &--dotted {
          flex: 0 0 53px;
          width: 1px;
          margin: 20px 0;

          overflow: hidden;
        }
      }
    }

    &__content {
      position: relative;
      margin-top: grid-size(1);

      text-align: center;
      font-weight: 800;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;

      @include mq(ipadP) {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: grid-size(0.25);
        font-size: 11px;

        transform: translateX(-50%);
      }
    }
  }
}