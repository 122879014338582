.download {
  &__wrapper {
    padding: grid-size(3) 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }

  &__icons {
    margin-top: grid-size(1.5);

    @include mq(ipadP) {
      margin-top: grid-size(1);
    }

    a {
      display: inline-block;
      margin: 0 grid-size(0.25);

      img {
        display: block;
        position: relative;
        width: grid-size(6);

        @include mq(ds) {
          width: grid-size(4);
        }
      }

      @include mq($and: $is-not-touch-device) {
        transition: opacity 0.5s $ease-base;

        &:hover {
          opacity: 0.3;
        }
      }
    }
  }
}