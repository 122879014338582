.footer {
  position: relative;
  width: 100%;
  padding: grid-size(1) grid-size(0.5);

  text-align: center;
  color: $color-white;
  background-color: $color-dark;
  font-size: 11px;

  @include mq(ipadP) {
    padding: grid-size(0.5) grid-size(1);
    font-size: 13px;
  }
}