
.spinner__button_root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner__button_button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner__button_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bta {
  height: 42px;
  background-color: $color-orange;
}

.orange {
  background-color: $color-orange;
}

.bta-pointer {
  :hover {
    cursor: pointer;
  }
}