@media (min-width: 768px) {
  .navigator {
    visibility: hidden;
  }
}

.navigator {
  @include full(true);

  z-index: z-index(header) - 5;

  &__bg {
    @include full();

    background-color: $color-background;
  }

  &__voices {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 22vh 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column nowrap;

    font-size: 22px;
    letter-spacing: 1px;

    a {
      font-weight: 800;
      color: inherit;
    }
  }
}