.form {
  width: calc(100vw - #{grid-size(1.5)});
  margin-top: grid-size(1);

  @include mq(ipadP) {
    width: 85vw;
  }

  @include mq(ds) {
    width: 60vw;
  }

  &__row {
    margin-bottom: grid-size(1);

    @include mq(ipadP) {
      margin-bottom: grid-size(0.5);

      &:not(.form__row--full) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: grid-size(0.5);
      }
    }
  }

  &__item {
    position: relative;
    padding: 15px 20px;

    border: 1px solid $color-gray;
    background-color: $color-white;
    -webkit-appearance: none;
    border-radius: 0;

    @include mq($until: ipadP) {
      margin-bottom: grid-size(1);
    }

    label {
      position: absolute;
      top: 15px;
      left: 20px;

      transition: opacity 0.25s $ease-base;

      pointer-events: none;

      sup {
        line-height: 1;
      }
    }

    input {
      width: 100%;
      background-color: transparent;

      &:focus,
      &:not(:placeholder-shown) {
        & + label {
          opacity: 0;
        }
      }
    }
  }

  &__submit {
    width: 100%;
    padding: 15px 20px;

    -webkit-appearance: none;
    border-radius: 0;
    background-color: $color-orange;
    color: $color-white;
    font-weight: 900;
    font-size: 11px;
    letter-spacing: 1px;

    cursor: pointer;

    @include mq(ipadP) {
      padding: 20px 60px;
    }
  }
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  text-align: left;
  margin-top: grid-size(1);
  font-weight: 800;
  font-size: 12px;

  @include mq($until: ipadP) {
    margin-top: grid-size(1.5);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    -webkit-appearance: none;
    border-radius: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;

    border: 1px solid $color-gray;
    background-color: $color-white;

    @include mq($until: ipadP) {
      height: 18px;
      width: 18px;
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    border: 1px solid $color-gray;
    background-color: $color-white;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark::after {
    transform: scale(0.5);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: $color-dark;

    transform: scale(0);
    transition: transform 0.75s $ease-base;
  }
}
