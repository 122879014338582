@mixin font-size(
  $m-font-size: 16px,
  $d-font-size: 20px,
  $m-line-height: 20px,
  $d-line-height: 24px,
  $m-ref: 375px,
  $d-ref: 1440px
) {
  font-size: max(14px, calc(#{strip-unit($m-font-size)} / #{strip-unit($m-ref)}) * 100 * 1vw);
  line-height: calc(#{strip-unit($m-line-height)} / #{strip-unit($m-font-size)});

  @media screen and (min-width: map-get($mq-breakpoints, ipadP)) {
    font-size: max(14px, calc((#{strip-unit($d-font-size)} / #{strip-unit($d-ref)}) * 100 * 1vw));
    line-height: calc(#{strip-unit($d-line-height)} / #{strip-unit($d-font-size)});
  }
}

@mixin aspect-ratio($width, $height) {
  &:before {
    content: '';

    display: block;

    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;
}

@mixin full($fixed: false) {
  top: 0;
  left: 0;

  @if $fixed {
    position: fixed;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  } @else {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

@mixin bottom-line($bottom: -5px, $height: 1px, $color: $color-dark) {
  &::after {
    content: '';

    position: absolute;
    bottom: $bottom;
    left: 0px;
    width: 100%;
    height: $height;

    background-color: $color;

    transform: scaleX(0);
    transform-origin: 0px 50%;
    transition: transform .3s $inOutCubic;
    transition-delay: .3s;
  }

  &::before {
    content: '';

    position: absolute;

    bottom: $bottom;
    left: 0px;
    width: 100%;
    height: $height;

    background-color: $color;

    transform: scaleX(1);
    transform-origin: 100% 50%;
    transition: transform .3s $inOutCubic;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
    }

    &::before {
      transform: scaleX(0);
    }
  }
}
