.hero {
  position: relative;
  width: 100%;
  min-height: 90vh;
  min-height: calc(var(--vh, 1vh) * 90);
  padding: grid-size(3.5) grid-size(1);

  @include mq(ipadP) {
    padding: grid-size(2.5) grid-size(1);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include mq(ipadP) {
      grid-template-columns: 40% 55%;
      column-gap: 5%;
    }
  }

  &__column {
    position: relative;

    &--centerd {
      align-self: center;

      @include mq($until: ipadP) {
        margin-bottom: grid-size(2);
      }
    }

    img {
      display: inline-block;
      position: relative;
      width: 100%;
    }
  }

  &__title {
    position: relative;
    margin-bottom: grid-size(1);

    @include mq(ipadP) {
      margin-bottom: grid-size(0.5);
    }
  }

  &__line {
    position: relative;
    width: grid-size(2.5);
    height: 2px;
    margin-bottom: grid-size(1);

    background-color: $color-dark;

    @include mq(ipadP) {
      width: grid-size(1.5);
      margin-bottom: grid-size(0.5);
    }
  }

  &__content {
    margin-bottom: grid-size(1);
  }
}
