.advantages {
  &__wrapper {
    padding: grid-size(3) grid-size(1);

    @include mq(ipadP) {
      padding: grid-size(3) grid-size(1);
    }
  }

  &__blocks {
    width: 100%;
    margin-top: grid-size(1.5);

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: grid-size(1);

    @include mq(ipadP) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: grid-size(0.5);
      row-gap: grid-size(0.5);
    }

    @include mq(ipadP) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: grid-size(1);
      row-gap: grid-size(1);
    }

    @include mq(d) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: grid-size(0.5);
      row-gap: grid-size(0.5);
    }

    li {
      flex: 0 0 grid-size(7);
      min-height: grid-size(12);
      padding: grid-size(3) grid-size(0.5) grid-size(2);
      border: 8px solid $color-dark;

      @include mq(ipadP) {
        padding: grid-size(2) grid-size(0.5) grid-size(0.5);
        min-height: grid-size(10);
      }

      @include mq(ipadL) {
        padding: 50px 20px 25px;
        min-height: grid-size(7);
      }

      @include mq(dl) {
        padding: 80px 40px 40px;
        min-height: 450px;
      }

      text-align: center;

      h4 {
        margin-bottom: grid-size(0.5);
        font-size: 20px;

        @include mq(dl) {
          font-size: 24px;
        }
      }

      &:nth-child(2) {
        border-color: $color-orange;
      }

      &:nth-child(3) {
        border-color: $color-gray;
      }

      &:nth-child(4) {
        border-color: $color-pink;
      }
    }
  }
}