.plans_button_container {
  flex: 1;
  align-items: flex-end;
  display: flex;
  width: 100%;
}

.plans,
.account {
  position: relative;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: grid-size(3) 20px;

  text-align: center;

  &__content {
    @include mq(ipadL) {
      width: 868px;
    }
  }

  &__boxes-container {
    @include mq($until: ipadP) {
      flex-flow: column nowrap;
      align-items: center;
    }
  }

  .box__button {
    width: 100%;
    text-decoration: none;
  }

  .box__description {
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  .box__description2 {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .print_icon {
    width: 162px;
    padding: 19px;
    margin-bottom: 22px;
  }

  .flex-05 {
    flex: 0.5;
  }

  .flex-1 {
    flex: 1;
  }

  .box {
    padding: 20px;
    margin: 20px;
    min-width: 350px;
    max-width: 350px;
    min-height: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      border: 8px solid $color-dark;

      .box__button {
        background-color: $color-dark;
      }
    }
    &:nth-child(2) {
      border: 8px solid $color-orange;
    }

    &__title {
      text-transform: uppercase;
      font-size: inherit;
      margin-bottom: 20px;
    }

    &__price {
      &__old {
        text-decoration: line-through;
        font-size: 20px;
      }
      &__new {
        font-size: 40px;
        font-weight: 900;
        line-height: 1;
      }
    }

    &__line {
      display: inline-block;
      position: relative;
      width: 50px;
      height: 2px;
      margin: 15px 0;

      background-color: $color-dark;
    }

    &__button {
      display: block;
      position: relative;

      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.account {
  .u-title {
    margin-bottom: 25px;
  }

  input[type=number] {
    width: 100px;
    margin: 0 auto;

    color: $color-gray;
    font-family: inherit;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    border-bottom: 1px solid $color-gray;

    &::placeholder {
      font-family: inherit;
      color: $color-gray;
      font-size: 40px;
      font-weight: 900;
    }
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .box {
    padding: 20px;
    margin: 20px;
    min-width: 350px;
    max-width: 350px;
    min-height: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .box__button {
      vertical-align: bottom;
      justify-self: flex-end;
      margin-top: auto;
    }

    .box__image {
      width: 80%;
      margin: 20px auto;
    }

    &:first-child .box__description {
      font-size: 16px;
      margin-top: 35px;
    }
    &:nth-child(3) {
      border: 8px solid $color-gray;

      .box__button {
        background-color: $color-gray;
      }
    }
    &:nth-child(4) {
      border: 8px solid $color-pink;

      .box__button {
        background-color: $color-pink;
      }
    }
  }
}