.half-picture {
  position: relative;
  width: 100%;

  @include mq(ipadP) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__column {
    position: relative;

    &--centered {
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        position: relative;
        width: 100%;
      }
    }

    &--picture img {
      position: relative;
      width: 100%;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: $color-orange;
  }

  @include mq($and: $is-touch-device) {
    .video--dsk {
      display: none;
    }
  }
  @include mq($and: $is-not-touch-device) {
    .video--mbl {
      display: none;
    }
  }
}