.sign-up {
  padding: 40px 20px;

  @include mq(ds) {
    padding: 65px 10vw;
  }

  .checkbox-container {
    margin-top: 0;
  }

  .form {
    width: 100%;
  }

  hr {
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;

    background-color: $color-gray;
  }

  h4 {
    margin-bottom: 10px;
  }

  .form__title {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .form__section {
    position: relative;
    width: 100%;
    padding: 20px 0;

    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include mq(ipadP) {
      grid-template-columns: 35% 60%;
      column-gap: 5%;
    }
  }

  .form__sign-in {
    width: 100%;
    margin-top: 20px;

    text-align: center;
    font-size: 13px;

    a {
      color: $color-orange;
      text-decoration: underline;
    }
  }
}