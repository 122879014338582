.contact {
  background-color: $color-pink;

  &__wrapper {
    padding: grid-size(3) 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;

    @include mq($until: ipadP) {
      text-align: center;
    }
  }

  .u-title {
    margin-bottom: grid-size(0.5);
  }
}