* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

::-moz-selection { // scss-lint:disable VendorPrefix
  color: $color-text;
  text-shadow: none;
}

::selection {
  color: inherit;
  text-shadow: none;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
  margin: 0;
  padding: 0;
  border: none;
}

html {
  -webkit-text-size-adjust: 100%;
}

progress {
  vertical-align: middle;
}

small {
  font-size: 80%;
}

b,
strong {
  display: inline-block;
  font-weight: 800;
}

a,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

a[href],
button {
  touch-action: manipulation;
}

q,
blockquote {
  font-style: italic;
}

ul {
  list-style: none;
}

audio,
canvas,
iframe,
svg,
video,
img {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  margin: 0;
  border: none;

  &:focus {
    outline: transparent;
  }
}

textarea {
  resize: none;
}

input::-moz-focus-inner {
  border: 0;
}

button {
  border: 0;
  background: transparent;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

hr {
  display: block;
}
