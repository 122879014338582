::selection {
  background: $color-pink; /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: $color-pink; /* Gecko Browsers */
}

:root {
  --grid-unit: 15;

  @include mq(ipadP) {
    --grid-unit: 32;
  }

  --grid-val: calc(100 / var(--grid-unit));

  @each $mq-key, $mq-value in $mq-breakpoints {
    @include mq($mq-key) {
      --breakpoints: '#{$mq-key}';
    }
  }
}

html,
body {
  overflow: hidden;

  @include full(true);

  @supports (-ms-ime-align:auto) {
    overflow-y: scroll;
  }
}

body {
  background: $color-background;
  color: $color-text;
  font-family: $font-family-base;
  font-size: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  color: $color-title;
}

#root,
.root {
  position: static;
  overflow-x: hidden;
  overflow-y: scroll;

  -webkit-overflow-scrolling: touch;
  // scroll-behavior: smooth;

  @include full(true);
}

a,
button {
  color: inherit;
  cursor: pointer;
}

section {
  position: relative;
}

.u-title {
  position: relative;
  font-weight: 900;
  @include font-size($fluid-section-title...);
}

.u-wrapper {
  max-width: 1440px;
  margin: 0 auto;

  @include mq(dm) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include mq(dl) {
    max-width: 1680px;
  }
  @include mq(dxl) {
    max-width: 1920px;
  }
}

.white {
  color: white !important;
}

.u-button-orange {
  padding: grid-size(0.5) grid-size(2);

  background-color: $color-orange;
  color: $color-white;
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 1px;

  @include mq(ipadP) {
    padding: 15px 60px;
  }
}

.filter-button {
  position: relative;
  padding: 12px 20px;
  margin-bottom: grid-size(2.5);

  border: 1px solid $color-gray;
  color: $color-gray;
  cursor: pointer;
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 1px;

  transition: background-color 0.75s $ease-base,
  border-color 0.75s $ease-base;

  @include mq(ipadP) {
    margin-bottom: grid-size(1.5);
    padding: 12px 30px;
  }

  &:first-child {
    margin-right: grid-size(0.5);

    @include mq($until: ipadP) {
      margin-bottom: grid-size(0.5) !important;
    }
  }

  &.filter-button--dark {
    border: 1px solid $color-gray;
    color: $color-gray;
  }

  &.filter-button--active {
    color: $color-white;
    background-color: $color-orange;
    border-color: transparent;
  }
}

@keyframes line {
  to {
    stroke-dashoffset: 0
  }
}
